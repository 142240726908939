// // Import material theming functions
// @import '~@angular/material/theming';


// // Copy the palettes from your selected theme (usually theme.scss).
// $app-primary: mat-palette($mat-grey);
// $app-accent:  mat-palette($mat-light-blue);
// $app-warn:    mat-palette($mat-pink);


// $primary: mat-color($app-primary);
// $accent: mat-color($app-accent);
// $warn: mat-color($app-warn);

// $my-color: map-get($weather-theme-primary, 50)\
:root {
    --mat-standard-button-toggle-selected-state-text-color: #408CE2;
    --sys-primary: #408CE2;
    --universal-white: #ffffff;
    --universal-black: #000;
    --neutral40-color: #605D64;
    --neutral80-color: #CAC5CD;
    --neutral90-color: #E6E0E9;
    --neutral96-color: #F7F2FA;
    --outlineVariant-color: #CAC4D0;
    --error-color: #ea5165;
    --light-error: #B3261E;
    --success-color: #02AC4D;
    --iframe-bg: #f7f7f7;
}

$primary-color: var(--mat-standard-button-toggle-selected-state-text-color);
