@use '@angular/material' as mat;
@import "bootstrap/scss/bootstrap-grid";
@include mat.core();
@include mat.all-component-typographies();


$theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$azure-palette,
    tertiary: mat.$blue-palette,
    use-system-variables: true, // 👈 Added
  ),
  typography: (
    plain-family: "Inter",    
    brand-family: 'Inter',    
    use-system-variables: true, // 👈 Added

  ),
));

:root {
  @include mat.all-component-themes($theme);
  @include mat.typography-hierarchy($theme);
  @include mat.system-level-colors($theme);
  @include mat.system-level-typography($theme);
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Inter, "Helvetica Neue", sans-serif;
}







// // This file was generated by running 'ng generate @angular/material:m3-theme'.
// // Proceed with caution if making changes to this file.

// @use 'sass:map';
// @use '@angular/material' as mat;

// // Note: Color palettes are generated from primary: #2d4186, secondary: #33c2b5
// $_palettes: (
//   primary: (
//     0: #000000,
//     10: #001551,
//     20: #0b2978,
//     25: #1b3584,
//     30: #294190,
//     35: #364d9c,
//     40: #4359a9,
//     50: #5c72c4,
//     60: #768ce0,
//     70: #91a7fd,
//     80: #b6c4ff,
//     90: #dce1ff,
//     95: #eff0ff,
//     98: #faf8ff,
//     99: #fefbff,
//     100: #ffffff,
//   ),
//   secondary: (
//     0: #000000,
//     10: #00201d,
//     20: #003733,
//     25: #00433e,
//     30: #00504a,
//     35: #005d56,
//     40: #006a62,
//     50: #00867c,
//     60: #00a296,
//     70: #28bfb2,
//     80: #51dbcd,
//     90: #72f8e9,
//     95: #b3fff5,
//     98: #e4fffa,
//     99: #f2fffc,
//     100: #ffffff,
//   ),
//   tertiary: (
//     0: #000000,
//     10: #2c1229,
//     20: #43273f,
//     25: #4f324b,
//     30: #5b3d57,
//     35: #684863,
//     40: #75546f,
//     50: #8f6c89,
//     60: #ab85a3,
//     70: #c79fbe,
//     80: #e3bada,
//     90: #ffd7f5,
//     95: #ffebf7,
//     98: #fff7f9,
//     99: #fffbff,
//     100: #ffffff,
//   ),
//   neutral: (
//     0: #000000,
//     10: #1b1b1f,
//     20: #303034,
//     25: #3b3b3f,
//     30: #47464a,
//     35: #525256,
//     40: #5e5e62,
//     50: #77767a,
//     60: #919094,
//     70: #acaaaf,
//     80: #c8c6ca,
//     90: #e4e1e6,
//     95: #f2f0f4,
//     98: #fbf8fd,
//     99: #fefbff,
//     100: #ffffff,
//     4: #0e0e11,
//     6: #131316,
//     12: #1f1f23,
//     17: #2a2a2d,
//     22: #343438,
//     24: #39393c,
//     87: #dbd9de,
//     92: #eae7ec,
//     94: #efedf1,
//     96: #f5f3f7,
//   ),
//   neutral-variant: (
//     0: #000000,
//     10: #1a1b23,
//     20: #2f3038,
//     25: #3a3b43,
//     30: #45464f,
//     35: #51525b,
//     40: #5d5e67,
//     50: #767680,
//     60: #90909a,
//     70: #aaaab4,
//     80: #c6c5d0,
//     90: #e2e1ec,
//     95: #f1f0fa,
//     98: #faf8ff,
//     99: #fefbff,
//     100: #ffffff,
//   ),
//   error: (
//     0: #000000,
//     10: #410002,
//     20: #690005,
//     25: #7e0007,
//     30: #93000a,
//     35: #a80710,
//     40: #ba1a1a,
//     50: #de3730,
//     60: #ff5449,
//     70: #ff897d,
//     80: #ffb4ab,
//     90: #ffdad6,
//     95: #ffedea,
//     98: #fff8f7,
//     99: #fffbff,
//     100: #ffffff,
//   ),
// );

// $_rest: (
//   secondary: map.get($_palettes, secondary),
//   neutral: map.get($_palettes, neutral),
//   neutral-variant: map.get($_palettes,  neutral-variant),
//   error: map.get($_palettes, error),
// );
// $_primary: map.merge(map.get($_palettes, primary), $_rest);
// $_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

// $light-theme: mat.define-theme((
//   color: (
//     theme-type: light,
//     primary: $_primary,
//     tertiary: $_tertiary,
//     use-system-variables: true,
//   ),
//   typography: (
//     use-system-variables: true,
//   ),
// ));
// $dark-theme: mat.define-theme((
//   color: (
//     theme-type: dark,
//     primary: $_primary,
//     tertiary: $_tertiary,
//     use-system-variables: true,
//   ),
//   typography: (

//     use-system-variables: true,
//   ),
// ));

// html{
//   @include mat.all-component-themes($light-theme);
// }