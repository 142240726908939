@import "./theme/variables";

:root {
  --mat-app-background-color: var(--universal-white);
  --mat-stepper-container-color: var(--universal-white);
  --mdc-elevated-card-container-color: var(--universal-white);
  --mdc-elevated-card-container-elevation: none;
  --sys-display-large: 400 32px Inter;
  --mat-switch-label-text-size: 16px;
  --sys-body-large-tracking: 0.5px;
  --mdc-filled-button-container-color: var(--universal-black);
  --mdc-filled-button-label-text-size: 16px;
  --mdc-filled-button-label-text-weight: 400;
  --mdc-filled-button-container-height: 50px;
  --mdc-linear-progress-track-shape: 2.5px;
  --mdc-linear-progress-track-color: var(--neutral80-color);
  --mat-switch-label-text-font: Inter, sans-serif;
  --mat-select-panel-background-color: var(--neutral90-color);
  --mat-form-field-error-hover-trailing-icon-color: var(--light-error);
  --mat-select-container-elevation-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  --mdc-text-button-label-text-weight: 400;

  --mat-standard-button-toggle-disabled-selected-state-background-color: var(--sys-primary);
  --mat-standard-button-toggle-disabled-selected-state-text-color: var(--universal-white);

  .mat-button-toggle-appearance-standard.mat-button-toggle-disabled {
    --mat-standard-button-toggle-disabled-state-text-color: var(--universal-black);
    color: var(--universal-black);
    opacity: 0.5;
  }

  .mat-button-toggle-appearance-standard.mat-button-toggle-disabled {
    background-color: var(--neutral90-color);
  }

  .back-btn {
    --mdc-icon-button-state-layer-size: 16px;
    --mdc-icon-button-icon-size: 16px;
    --mdc-icon-button-icon-color: transparent;
    --mat-text-button-state-layer-color: transparent;
    --mat-text-button-pressed-state-layer-opacity: 0;

    .mat-button-focus-overlay {
      background-color: transparent !important;
    }

    .mdc-icon-button__ripple,
    .mat-mdc-focus-indicator {
      display: none !important;
    }
  }
}

body {
  background: var(--universal-white);
}

.hidden-until-loaded {
  display: none;
}

.thank-you-wrap {
  padding-top: 50px;
}

app-root {
  background: var(--iframe-bg);
  border: none;
  padding: 0;

  main {
    border: none;
  }

  &.embedded {
    padding: 30px 0;
    border-top: 1px solid var(--outlineVariant-color);
    border-bottom: 1px solid var(--outlineVariant-color);

    main {
      border-top: 1px solid var(--outlineVariant-color);
      border-bottom: 1px solid var(--outlineVariant-color);
    }

    &.popup {
      border: none;
      padding: 0;
      height: auto !important;
      min-height: calc(100vh - 72px);

      main {
        border: none;
      }
    }
  }
}

::ng-deep .mat-button-ripple {
  border-radius: inherit;
}

.show-for-large {
  display: none !important;
}

mat-button-toggle-group.button-group {
  flex-wrap: wrap;
  display: flex;
  justify-content: left;
  align-items: left;
  border: none;
  border-radius: 0px;
  padding: 0;
  gap: 16px;

  mat-button-toggle {
    border-radius: 40px;
    background: var(--neutral90-color);
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    overflow: hidden;

    &.mat-button-toggle-checked {
      background: var(--sys-primary);
      color: var(--universal-white);
    }
  }

  &.mat-button-toggle-group-appearance-standard .mat-button-toggle-appearance-standard+.mat-button-toggle-appearance-standard {
    border-left: 0;
  }
}

mat-form-field.form-field {
  width: 100%;
}

.mat-title-medium {
  margin-bottom: 0 !important;
  font-size: 16px;
  font-weight: 500;
  color: var(--neutral40-color);
}

.group-section {
  width: 100%;

  .section-title {
    .section-subtitle {
      color: var(--neutral40-color);
      display: block;
    }
  }
}

.help-tip {
  color: var(--sys-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.info-tooltip {
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  font-size: 16px;
  width: 16px !important;
  height: 17px !important;
  vertical-align: text-top;
  margin-left: 0.25rem;
}

.check-icon {
  display: block !important;
  margin-bottom: 16px;
  width: 40px !important;
  height: 40px !important;
}

.expenses-list {
  margin: 0;
  display: flex;
  gap: 16px;
  flex-direction: column;

  li {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    place-items: center;
    gap: 16px;
  }
}

.link-button {
  --mdc-text-button-label-text-size: 16px;
  --mat-text-button-with-icon-horizontal-padding: 0;

  &.remove {
    --mdc-text-button-label-text-color: var(--light-error);
  }
}

:root {

  .no-background-link,
  .no-hover-bg {
    --mat-text-button-state-layer-color: transparent;
    --mat-text-button-pressed-state-layer-opacity: 0;
    --mat-text-button-horizontal-padding: 0;

    .mat-button-focus-overlay {
      background-color: transparent !important;
    }

    .mat-ripple {
      display: none !important;
    }
  }

  .height-24 {
    height: 24px;
  }
}

.neutral40 {
  color: var(--neutral40-color);
}

hr.separator {
  border: none;
  border-top: 1px solid var(--outlineVariant-color);
  margin: 50px 0;

  &.no-margin-top {
    margin-top: 0;
  }

  &.no-margin {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error-snackbar {
  background: var(--light-error);
  white-space: pre-wrap;
}

.error-text {
  color: var(--light-error);
}

.container {
  max-width: 548px;
  padding: 0;
}

.mat-horizontal-content-container {
  padding: 0 !important;
  overflow: visible !important;
}

.row {
  margin-left: 0;
  margin-right: 0;

  .row:not(:has(.col-12)) {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
}

.col-12 {
  padding: 0 24px;

  .col-12 {
    padding: 0;
  }
}

.height-24 {
  --mdc-text-button-container-height: 24px;
}

.black-font {
  color: var(--universal-black) !important;
}

.italic-text {
  font-style: italic;
}

.no-letterspace {
  letter-spacing: 0 !important;
}

.dealer-logo {
  margin-bottom: 29px;
  width: 200px;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;

  img {
    height: min-content;
    width: 100%;
  }
}

.tick-list {
  margin-left: 0;
  padding-left: 0;
  margin-top: 10px;
  margin-bottom: 42px;

  li {
    list-style: none;
    display: flex;
    flex-direction: row;
    place-items: center;
    gap: 8px;
    margin-bottom: 8px;

    &:before {
      content: "";
      background: url("/assets/svg/check-small.svg") no-repeat;
      width: 24px;
      height: 24px;
    }
  }
}

// button style
mat-flat-button,
.mdc-button {
  line-height: 50px;
  padding: 0 30px;
}

.mat-mdc-unelevated-button:not(:disabled) {
  padding: 0 30px;
  width: 100%;

  &.landing-btn {
    width: auto;
  }
}

.landing-img{
  width: 100%;
  max-width: 391px;
}

// progress bar style
.progress-container {
  display: flex;
  flex-direction: row;
  place-items: center;
  gap: 16px;
  margin-bottom: 50px;
  margin-top: 50px;
}

.mat-mdc-progress-bar {
  width: 225px;
  border-radius: 2.5px;
  height: 5px;
}

.mdc-linear-progress__bar-inner {
  border-radius: var(--mdc-linear-progress-track-shape);
}

:host ::ng-deep .mat-mdc-progress-bar .mat-progress-bar-fill::after {
  border-top-right-radius: 50% 25px;
  border-bottom-right-radius: 50% 25px;
  border-right: 4px solid va(--universal-white);
}

.back-btn {
  padding: 0 !important;
  --mat-mdc-button-persistent-ripple-color: none;

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}

.flex-content {
  display: flex;
  justify-content: space-between;
  place-items: center;

  &.text-end {
    justify-content: flex-end;
    place-items: flex-end;
  }

  &.stacked-text {
    flex-direction: column;
    gap: 0;
    margin-top: 10px;
  }
}

// group spacing using flex gap
.mat-horizontal-content-container {
  section {
    display: flex;
    flex-direction: column;
    gap: 50px;

    &.input-section {
      gap: 32px;
    }

    p {
      margin: 0 !important;
    }

    label,
    p {
      a {
        text-decoration: none;
        color: var(--sys-primary);

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .mat-headline-large {
      margin-bottom: 0 !important;
    }
  }
}

.section-spacing {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.group-required {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.mat-mdc-form-field-error {
  font-size: 12px;
  line-height: 18px;

  a {
    text-decoration: none;
    color: var(--sys-primary);

    &:hover {
      text-decoration: underline;
    }
  }
}

.primary-text {
  display: flex;
  place-items: center;
  justify-content: flex-start;
  gap: 5px;

  mat-icon {
    width: 18px;
  }
}

.group-section {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &:empty {
    display: none;
  }

  &.info-box {
    background: var(--neutral96-color);
    border-radius: 8px;
    padding: 30px 35px;
    margin-top: 50px;
    gap: 14px;
    width: auto;

    &.dl-wrap {
      margin: 0;
    }

    .mat-body-large,
    .mat-body-small {
      color: var(--neutral40-color);
    }
  }

  &.small-group {
    gap: 24px;
  }

  &.group-16 {
    gap: 16px;
  }

  &.x-small-group {
    gap: 12px;
  }

  &.title-section {
    gap: 4px;
  }

  app-form-button-group-simple {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.mat-horizontal-content-container section p.margin-bottom-50 {
  margin-bottom: 50px !important;
}


mat-slide-toggle .mat-internal-form-field {
  display: flex;
  gap: 25px;
  flex-direction: row;
  --mat-switch-label-text-size: 16px;
  --mat-switch-label-text-line-height: 24px;
  --mat-switch-label-text-font: Inter, sans-serif;

  >label {
    padding-left: 0;
    font-size: var(--mat-switch-label-text-size);
  }
}

.powered-by {
  color: var(--neutral40-color);
  font-size: 12px;
  letter-spacing: 0.4px;
  display: flex;
  flex-direction: row;
  gap: 6px;
  margin-top: 20px;
  margin-bottom: 68px;
  justify-content: center;

  &.landing-powered {
    justify-content: flex-start;
    margin-bottom: 20px;
  }
}

.landing-content {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column-reverse;
  gap: 70px;
}

.uppercase,
app-vehicle-lookup {
  text-transform: uppercase;

  mat-label,
  mat-hint,
  mat-error,
  mat-placeholder,
  .mat-label,
  .mat-hint,
  .mat-error,
  .mat-placeholder,
  .mat-form-field-label,
  .mat-form-field-placeholder,
  .mat-form-field-hint,
  .mat-form-field-error,
  :placeholder-shown,
  ::placeholder {
    text-transform: none;
  }

  .results {
    text-transform: none;
    font-size: 12px;
    padding: 0 1rem;

    .success {
      color: var(--success-color);
    }

    .error {
      color: var(--light-error);
    }
  }
}

.green-text {
  color: var(--success-color);
}

.error-icon {
  color: var(--light-error);
  --mat-icon-color: var(--light-error);
}

.success-icon {
  color: var(--success-color);
  --mat-icon-color: var(--success-color);
}

.loading-icon {
  color: var(--sys-primary);
  --mat-icon-color: var(--sys-primary);
}

.spinner-container {
  display: flex;
  justify-content: center;
  place-items: center;
  padding: 4rem 0;
  flex-direction: column;
  gap: 3rem;

  img {
    width: 100%;
    max-width: 220px;
  }
}

@media screen and (min-width: 640px) {}

@media screen and (min-width: 760px) {
  body {
    background: transparent;
  }

  app-root {
    padding: 2rem;

    &.popup {
      padding: 2rem;

      main {
        border: 1px solid var(--outlineVariant-color);
      }

      &.embedded {
        padding: 2rem;

        main {
          border: 1px solid var(--outlineVariant-color);
        }
      }
    }
  }

  .thank-you-wrap {
    padding-top: 68px;
  }
}

@media screen and (min-width: 1024px) {
  .show-for-large {
    display: block !important;

    &.flex-content {
      display: flex !important;
    }
  }

  .hide-for-large {
    display: none;
  }

  .landing-container {
    max-width: 892px;
  }

  .landing-content {
    padding: 50px 0;
    display: flex;
    flex-direction: row;
    place-items: center;
    justify-content: space-between;
  }
}

.loading {

  form,
  code-input {
    opacity: 0.5;
    filter: grayscale(1);
    cursor: default;
    pointer-events: none;
  }
}